import * as echarts from "echarts";
const fullConfig = require("../tailwind.config.js");

export const playbookChart = (data) => {
  return {
    lineChart: null,
    lineChartInstance: null,
    lineTotalChartOptions: null,

    init() {
      this.cacheElements();
      this.initChartsOptions();
    },

    cacheElements() {
      this.lineChart = document.getElementById("playbook-line-chart");

      if (this.lineChart) {
        this.lineChartInstance = echarts.init(this.lineChart);
      }

      window.addEventListener("resize", () => {
        if (this.lineChart) {
          this.lineChartInstance.resize();
        }
      });
    },

    initChartsOptions() {
      this.lineTotalChartOptions = this.lineChartOptions(
        data.map((s) => s.month),
        data.map((s) => s.avg_score),
        "Score",
        "Score",
        data.map((s) => `${Math.round(s.avg_score * 100.0)}%`),
      );
    },

    getMonthStartDate(dateString) {
      const date = new Date(dateString);

      const monthStartDate = new Date(date.getFullYear(), date.getMonth(), 1);

      const day = String(monthStartDate.getDate()).padStart(2, "0");
      const monthName = monthStartDate.toLocaleString("default", {
        month: "short",
      });

      return `${monthName} ${date.getFullYear()}`;
    },

    formatMonth(inputDate) {
      return this.getMonthStartDate(inputDate);
    },

    lineChartOptions(xData, yData, yLabel, tooltipTitle, valueLabels) {
      return {
        grid: { top: 10, right: 70, left: 70 },
        xAxis: {
          type: "category",
          data: xData,
          name: "Period",
          nameTextStyle: {
            color: "#667085",
            margin: 15,
          },
          nameLocation: "center",
          nameGap: 50,
          boundaryGap: false,
          axisLabel: {
            show: true,
            color: "#667085",
            formatter: (param) => this.formatMonth(param),
          },
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          minorSplitLine: { show: false },
        },
        yAxis: {
          axisTick: { interval: 1 },
          type: "value",
          name: yLabel,
          nameTextStyle: {
            color: "#667085",
            margin: 15,
          },
          nameLocation: "center",
          nameGap: 50,
          axisLabel: {
            show: true,
            color: "#667085",
            formatter: (params) => `${Math.round(params * 100.0)} %`,
          },
        },
        legend: {},
        tooltip: {
          trigger: "axis",
          position: (point) => [point[0] + 10, point[1] - 50],
          padding: [0, 0],
          backgroundColor: "#ffffff",
          borderColor: "#ffffff",
          formatter: (params) => `
            <div class="inline-flex flex-col items-start shadow-md">
              <div
                class="flex items-center gap-2.5 self-stretch p-2 px-2.5 pt-1"
                style="border-radius: 0.25rem 0.25rem 0rem 0rem;
                   border-top: 1px solid var(--Blue-gray-100, #EAECF5);
                   border-right: 1px solid var(--Blue-gray-100, #EAECF5);
                   border-left: 1px solid var(--Blue-gray-100, #EAECF5);
                   background: var(--Blue-gray-50, #F8F9FC);"
              >
                <span
                  class="text-gray-500"
                  style="font-size: 0.625rem; font-style: normal; font-weight: 600; line-height: 1rem;"
                >
                  ${this.formatMonth(params[0].name)}
                </span>
              </div>
              <div
                class="flex items-center gap-2.5 p-2 px-2.5 pt-2.5"
                style="border-radius: 0rem 0rem 0.25rem 0.25rem;
                    border-right: 1px solid var(--Blue-gray-100, #EAECF5);
                    border-bottom: 1px solid var(--Blue-gray-100, #EAECF5);
                    border-left: 1px solid var(--Blue-gray-100, #EAECF5);
                    background: var(--White, #FFF);"
              >
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="5" cy="5" r="5" fill="#2E90FA" />
                </svg>
                <span
                  class="text-gray-500"
                  style="align-self: stretch; font-size: 0.625rem; font-style: normal; font-weight: 400; line-height: 1.0625rem;"
                >
                  ${tooltipTitle}:
                  <span
                    class="text-primary-500"
                    style="font-size: 0.625rem; font-style: normal; font-weight: 700; line-height: 1.25rem;"
                  >
                    ${valueLabels[params[0].dataIndex]}
                  </span>
                </span>
              </div>
            </div>`,
        },
        toolbox: { show: false },
        series: [
          {
            data: yData,
            symbol: "circle",
            showSymbol: false,
            type: "line",
            smooth: true,
            lineStyle: { color: "#1570EF", width: 5 },
            itemStyle: {
              color: "#B2DDFF",
              borderColor: "#53B1FD",
              borderRadius: 1,
              borderWidth: 10,
              symbolSize: 10,
            },
            emphasis: {
              focus: "series",
              itemStyle: {
                color: "#B2DDFF",
                borderColor: "#53B1FD",
                borderWidth: 15,
                symbolSize: 10,
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: "rgba(0, 123, 255, 0.6)" },
                  { offset: 1, color: "rgba(0, 123, 255, 0.0)" },
                ],
                global: false,
              },
            },
          },
        ],
      };
    },

    setDisplayChart() {
      if (this.lineChart) {
        echarts
          .getInstanceByDom(this.lineChart)
          .setOption(this.lineTotalChartOptions);
      }
    },
  };
};
