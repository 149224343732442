export const myProfile = () => {
  return {
    isWaitingForResponse: false,
    tabSelected: "information",
    redirectUrl: "",

    init() {
      const path = window.location.pathname;

      this.tabSelected = "information";
      if (path.includes("my-calendar")) {
        this.tabSelected = "my_calendar";
      } else if (path.includes("my-integrations")) {
        this.tabSelected = "my_integrations";
      }

      this.tabRepositionMarker(this.$refs[this.tabSelected]);

      setTimeout(() => {
        this.tabRepositionMarker(this.$refs[this.tabSelected]);
      }, 100);
    },

    tabRepositionMarker(tabButton) {
      this.$refs.tabMarker.style.width = tabButton.offsetWidth + "px";
      this.$refs.tabMarker.style.height = tabButton.offsetHeight + "px";
      this.$refs.tabMarker.style.left = tabButton.offsetLeft + "px";
    },

    tabButtonClicked(tabId) {
      this.tabSelected = tabId;
      this.tabRepositionMarker(this.$refs[tabId]);
    },
  };
};
