import Alpine from "alpinejs";
// For some reason it is needed to import htmx from another file in order to be able to add htmx to window
import htmx from "./htmx";
import { callVideoPlayer } from "./video-player";
import { speechBlockCard, transcriptTab } from "./transcript-tab";
import { aiSummaryTab } from "./ai-summary-tab";
import { aiCoachTab } from "./ai-coach-tab";
import {
  dateFormatter,
  timeAgo,
  dateFormatterDDMMYYYY,
  dateFormatterTimeOnly,
} from "./date-formatter";
import { currencyFormatter } from "./currency-formatter";
import { shareVideoModal } from "./share-video-modal";
import { sharedVideoPlayer } from "./shared-video-player";
import { createSnippet } from "./create-snippet";
import { callVideoTimeline } from "./video-timeline";
import focus from "@alpinejs/focus";
import { displayChart } from "./chart";
import { integrationForm } from "./integration-form";
import { integrations } from "./integrations";
import { myProfile } from "./my-profile";
import { multiSelect } from "./multi-select";
import { globalData } from "./global";
import { richTextArea } from "./rich-text-area";
import { playbookChart } from "./playbook-chart";
import localStorageHelper from "./local-storage-helper";

window.Alpine = Alpine;
Alpine.plugin(focus);

htmx.config.allowNestedOobSwaps = false;
htmx.config.useTemplateFragments = true;

Alpine.data("globalData", globalData);
Alpine.data("callVideoPlayer", callVideoPlayer);
Alpine.data("aiSummaryTab", aiSummaryTab);
Alpine.data("transcriptTab", transcriptTab);
Alpine.data("speechBlockCard", speechBlockCard);
Alpine.data("aiCoachTab", aiCoachTab);
Alpine.data("dateFormatter", dateFormatter);
Alpine.data("timeAgo", timeAgo);
Alpine.data("dateFormatterDDMMYYYY", dateFormatterDDMMYYYY);
Alpine.data("dateFormatterTimeOnly", dateFormatterTimeOnly);
Alpine.data("currencyFormatter", currencyFormatter);
Alpine.data("shareVideoModal", shareVideoModal);
Alpine.data("sharedVideoPlayer", sharedVideoPlayer);
Alpine.data("createSnippet", createSnippet);
Alpine.data("callVideoTimeline", callVideoTimeline);
Alpine.data("displayChart", displayChart);
Alpine.data("integrations", integrations);
Alpine.data("integrationForm", integrationForm);
Alpine.data("myProfile", myProfile);
Alpine.data("multiSelect", multiSelect);
Alpine.data("richTextArea", richTextArea);
Alpine.data("playbookChart", playbookChart);

Alpine.start();

document.addEventListener("htmx:configRequest", (event) => {
  event.detail.headers["Content-Type"] = "application/json";
});

htmx.on("showNotification", (event) => {
  localStorage.setItem(
    "notification",
    JSON.stringify({
      notificationType: event.detail.notificationType,
      message: event.detail.message,
      content: event.detail.content,
    }),
  );
  if (event.detail.redirectUrl) {
    location.href = event.detail.redirectUrl;
  } else if (event.detail.goBack) {
    location.href = document.referrer;
  } else if (event.detail.refreshPage) {
    location.reload();
  }
});

document.body.addEventListener("htmx:afterProcessNode", (evt) => {
  let storedNotificationStr = localStorage.getItem("notification");
  if (storedNotificationStr) {
    let storedNotification = JSON.parse(storedNotificationStr);
    localStorage.removeItem("notification");
    htmx.ajax("POST", "/notification", {
      target: "#settings_notification",
      swap: "outerHTML",
      values: {
        notificationType: storedNotification.notificationType,
        message: storedNotification.message,
        content: storedNotification.content,
      },
    });
  }
});

document.body.addEventListener("htmx:afterOnLoad", function () {
  handleAuthCallback();
});

function handleAuthCallback() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  if (params.has("code")) {
    let authCallbackUrl = undefined;
    if (url.pathname.includes("salesforce")) {
      authCallbackUrl = "/settings/form/integrations/salesforce-auth-callback";
    } else if (url.pathname.includes("hubspot")) {
      authCallbackUrl = "/settings/form/integrations/hubspot-auth-callback";
    } else if (url.pathname.includes("google")) {
      authCallbackUrl =
        "/settings/my-profile/my-calendars/connect/google_calendar/auth-callback";
    } else if (url.pathname.includes("outlook")) {
      authCallbackUrl =
        "/settings/my-profile/my-calendars/connect/outlook_calendar/auth-callback";
    }

    const code = params.get("code");
    localStorageHelper.removeQueryParam("code");

    htmx.ajax("POST", authCallbackUrl, {
      values: {
        code: params.get("code"),
      },
    });
  }
}

function isMultipleSelect(formElement, name) {
  // Check if the form element is provided and is indeed a form
  if (!formElement || !(formElement instanceof HTMLFormElement)) {
    return false;
  }

  // Get the element by name
  const element = formElement.elements[name];

  // Check if the element exists
  if (!element) {
    console.warn(`No element found with name: ${name}`);
    return false;
  }

  // Check if it's a select element with the multiple attribute
  return element.tagName === "SELECT" && element.hasAttribute("multiple");
}

function getEmptyArraysForMultiSelects(elt) {
  // Find all <select multiple> elements in the form
  const multiSelects = elt.querySelectorAll("select[multiple]");
  let object = {};
  multiSelects.forEach((select) => {
    const name = select.name;

    // If the select's name is not in the FormData, add an empty array
    if (!(name in object)) {
      object[name] = [];
    }
  });

  return object;
}

function extractObjectsFromFormData(elt) {
  let formData;
  try {
    formData = new FormData(elt);
  } catch (error) {
    formData = new FormData();
  }

  let object = {};
  for (const [key, value] of formData.entries()) {
    object[key] = isMultipleSelect(elt, key) ? formData.getAll(key) : value;
  }
  return object;
}

function createJsonFromElement(elt) {
  let object = extractObjectsFromFormData(elt);

  object = { ...getEmptyArraysForMultiSelects(elt), ...object };

  object = { ...extractValuesFromHxVals(elt), ...object };

  object = { ...extractValuesFromHxInclude(elt), ...object };

  return object;
}

/**
 * This extension takes inspiration from the https://github.com/Emtyloc/json-enc-custom extension.
 * The original one does not fit our case, because it misses the two following properties that we need:
 * - Set the <select multiple> with no selection to empty array
 * - When the <select multiple> has only one element selected, wrap it in an array instead of a plain string.
 *
 */

htmx.defineExtension("json-enc-custom", {
  encodeParameters: function (xhr, parameters, elt) {
    xhr.overrideMimeType("text/json");
    const object = createJsonFromElement(elt);
    return JSON.stringify(object);
  },
});

function extractValuesFromHxInclude(elt) {
  const hxInclude = elt.getAttribute("hx-include");
  if (hxInclude) {
    const element = document.querySelector(hxInclude);
    if (element) {
      return createJsonFromElement(element);
    }
  }
  return {};
}

function extractValuesFromHxVals(elt) {
  const hxVals = elt.getAttribute("hx-vals");
  if (hxVals) {
    try {
      return JSON.parse(hxVals);
    } catch (parseError) {
      console.error("Failed to parse hx-vals:", parseError);
    }
  }
  return {};
}
